import React             from 'react';
import Img               from 'gatsby-image';
import styled            from '@emotion/styled';
import { useMediaQuery } from '@chakra-ui/react';
import FramedImage       from './FramedImage';

const Container = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: ${props => props.inverted ? 'row-reverse' : 'row'};
    width: 100% !important;
    overflow: hidden;
    margin-bottom: 20px;
    @media (max-width: 1040px) {
        flex-direction: column;
    }
`;

const Image = styled.div`
    width: 50%;

    > .gatsby-image-wrapper {
        width: 100%;
    }

    @media (max-width: 1068px) {

    }
    @media (max-width: 1040px) {
        width: 90%;
        > .gatsby-image-wrapper {
            width: 100%;
        }

        margin: auto;
        padding: 0;
    }
`;

const Text = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 0 4vw;

    h3 {
        margin-bottom: 10px;
    }

    @media (max-width: 1040px) {
        width: 100%;
        padding: 20px 0;
        > div {
            padding: 0;
            width: 90%;
            margin: auto;
        }

        margin-top: 20px;
    }
`;

const Checkboard = ({ title, image, imageSet, children, inverted, logoSet }) => {
  const [portrait] = useMediaQuery('(max-width: 768px)');
  return (
    <Container inverted={inverted}>
      <Image inverted={inverted}>
        <FramedImage>
          {imageSet && <Img fluid={portrait ? imageSet.portrait : imageSet.landscape}/>}
          {image && <Img fluid={image}/>}
        </FramedImage>
      </Image>
      <Text inverted={inverted}>
        <div>
          {logoSet && <Img fixed={portrait ? logoSet.portrait : logoSet.landscape}/>}
          {title && <h3 style={{ textTransform: 'uppercase' }}>{title}</h3>}
          <>
            {children}
          </>
        </div>
      </Text>
    </Container>
  )
};

export default Checkboard